import React from 'react';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Solution from '../Solution';
import Press  from '../Press';

import Footer from '../Footer';
import StoryCards from '../StoryCards';
import Description from '../Description'; // Import the AlectoAIDescription
import NewsletterSignup from '../NewsletterSignup';
//import Solution from '../Solution'; // Corrected the import statement
import Mission from '../Mission';

import '../../App.css';

function Home() {
  return (
    <>
      <div style={{ backgroundColor: 'black' }}>
        <HeroSection />
        <Description />
        <Solution />
        <Mission />
        <Cards />
        <Press/>
        <NewsletterSignup/>
        <Footer />
      </div>
    </>
  );
}

export default Home;
