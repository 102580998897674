import React from 'react';
import '../../App.css';
import Abouthero from "../Abouthero"
import Aboutmission from '../Aboutmission'
import Aboutsolution from '../Aboutsolution';
import Aboutstory from '../Aboutstory';
import Aboutphones from '../Aboutphones';
import NewsletterSignup from '../NewsletterSignup';
import styled from 'styled-components';
import Footer from '../Footer';
const PageContainer = styled.div`
  background-color: black;
`;
export default function AppPreview() {
  return (
    <>
    <PageContainer>
      <Abouthero/>
      <Aboutmission />
      <Aboutsolution />
      <Aboutstory />
      <Aboutphones />
      <NewsletterSignup/>
      <Footer />
      </PageContainer>

    </>
  );
}











// import React from 'react';
// import '../../App.css';
// import WhyPage from '../WhyPage';
// import Footer from '../Footer';

// export default function AppPreview() {
//   return (
//     <>
     
//       <WhyPage />
//       <Footer />

//     </>
//   );
// }