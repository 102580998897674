import React from 'react';
import '../App.css';

import './TheStory.css';

function TheStory() {
  return (
    <div className='hero-container-s'>
      <h1>The Story</h1>
      <p>Alecto’s founder is a revenge porn survivor, someone she once dated filmed her without her consent and posted the video on Pornhub without her knowledge. 
        <br/>When she initially found out about this incident, she attempted to commit suicide, the shame almost destroyed her. She encountered unimaginable obstacles during her battle against revenge porn. 
        <br/>In her darkest hour, she was forced to relive her trauma over and over again while going through various different porn sites and asking them to remove her video. Despite her relentless effort, the road to seek justice was never easy. 
        <br/>Her experience inspired her to found this company and embark on the journey to help others. </p>
        <br/><br/><br/><h2>Let's keep the flame burning, <br/>share your story, <br/>and stand with us for a better future! </h2>
        <br/>
      </div>
        
     

  );
}

export default TheStory;