import React from 'react';
import '../../App.css';

import Footer from '../Footer';
import NewsPage from '../NewsPage';

export default function News() {
  return (
    <>
      
      <NewsPage />
      <Footer />
    </>
  );
}