import React from 'react';
import '../../App.css';
import ProductHeroSection from '../ProductHeroSection';
import Footer from '../Footer';
import AppPreview from './AppPreview';

export default function Products() {
  return (
    <>
      <ProductHeroSection />
      <AppPreview />
      <Footer />
    </>
  );
}