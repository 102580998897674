import React from 'react';
import '../App.css';
import './BetaPage.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';



function BetaPage() {
  return (
    <div className='container-pb'>
      <h1>Public Beta Test</h1>
      <div className="containerb">
          <img
            className="images/p5.png"
            src="images/p5.png"
            alt="Profile"
            style={{ marginTop: "50px", display: "inline-block;" }}
          ></img>
    
          <k>
          <p>
          Join Our Public Beta Test:
          </p>
             At Alecto AI, we are focused on ending online image abuse with compassion and cutting-edge technology. We are proud to announce that we have started releasing our public beta test! <br />
             <br />
             <t>Please follow the instructions to join our beta test. <br/>
              And leave your valuable thoughts in the google form below.</t>
              <div className='hero-btns'>

        <button type="button" class="btn btn-outline-light btn-lg btn-block"><Link to="/beta-test" style={{color: "black",textDecoration:"none"}}>Download Beta Test</Link></button>
      <button type="button" class="btn btn-outline-light btn-lg btn-block"><Link to="/beta-feedback" style={{color: "black",textDecoration:"none"}}>Beta Feedback</Link></button>
      <button type="button" class="btn btn-outline-light btn-lg btn-block"><Link to="/terms" style={{color: "black",textDecoration:"none"}}>Terms of Service</Link></button>
      </div>
      
     
      </k>
         
    </div>
    <h3>To start using the app:</h3>
    <h4>1. Update your iPhone or iPad to iOS 15.0 or later; you might experience unexpected crashes if your iOS is not updated (at this time, we only have the beta on the Apple app store);<br />
              2. Click the download link and follow the instructions;<br />
               (Note: To join the Alecto AI beta, open the link on your iPhone, iPad, or Mac after you install TestFlight.)<br />
              3. Review the Terms of Service;<br />
              4. After opening the app, create an account with your email;<br />
              5. Go into the Profile tab, and click “Verify Your Account” to start the verification process. Once our team member confirms your identity, you will gain full access to the beta test! We implemented this step to ensure only you can access the search results associated with your face.<br />
              <br /></h4>     
    <h4>Please note that in the beta, we cannot directly remove images or videos hosted on other platforms. Certain app features are currently disabled in order to protect your privacy and avoid our technology being abused by stalkers. If you encounter images or videos posted without your permission, please contact your local law enforcement. <br />
We are committed to listening to our community, so if you have any feedback please fill out our feedback form! <br />
In the upcoming versions of the beta, we plan on prioritizing improvements to our facial recognition AI, expanding the community hub to allow making private posts, and incorporating feedback from our users.<br /><br />
</h4>
  
    </div>
  );
}

export default BetaPage;