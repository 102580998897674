import React from 'react';
import styled from 'styled-components';
import '../App.css';
import './TheStory.css';

const Container = styled.div`
  padding: 2rem;
  background-color: black;
  color: white;
  min-height: 100vh;
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Title = styled.h1`
  color: white;
  text-align: center;
  margin-bottom: 2rem;
  border: 1px solid black;
`;

const Content = styled.p`
  color: white;
  border: 1px solid none;
  padding: 50px;
  background-color:#1a1a1a;
  
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Underlined = styled.u`
  color: white;
`;

const PrivacyPage = () => {
  return (
    <Container>
      <Title>Alecto AI Privacy Notice</Title>
      <Content>
        Welcome to Alecto AI. Alecto AI is built and owned by Alecto AI, Inc. (“Alecto AI,” “we,” “us,” “our”). This Privacy Notice (“Notice”) applies to your (“you,” “your”) use of the Alecto AI app, website, and services (“our Services”). Your use of our Services constitutes your acknowledgement of this Notice. If you object to the terms of this Notice, please do not use our Services.
        <br/><br/>
        We may update this Notice from time to time, and we will update the date that the Notice was Last Updated when we do so. You should regularly review the Notice when you use our Services.
        <br/><br/>
        <Underlined>INFORMATION WE COLLECT</Underlined>
        <br/>
        When you use our Services, we collect the following categories of information:
        <br/>• Identifiers: We collect information that can be used to identify you, such as your email, phone number, username and password. We also may collect names of business partners that use our Services.
        <br/>• Electronic network activity information: We collect information related to your electronic network activity, such as information about your interactions with our Services and information about your device.
        <br/>• Audio and visual information: We may collect audio and visual information when you use our Services, for example, when you submit an image and video of yourself to access certain features of our Services.
        <br/>• Biometric data: We may collect certain biometric data, such as a faceprint, when you choose to share photo or video content with us [and with your consent]. We do not use or intend to use this data to identify you.
        <br/>• Inferences: We may collect inferences drawn from the other information described above.
        <br/><br/>
        We may aggregate or de-identify the information described above. Aggregated or de-identified data that we do not attempt to reidentify is not subject to this Privacy Notice.
        <br/><br/>
        We do not intend for our Services to be used by individuals under the age of 13, and we do not knowingly collect the personal information of individuals under 13.
        <br/><br/>
        <Underlined>Special notice on face data</Underlined>
        <br/><br/>
        • Currently, all search functions are limited to an internal database, we do not share any of your personal information with any third parties. 
        <br/><br/>
        •The verification process was designed to protect users’ privacy and prevent Alecto AI from being abused by unauthorized third parties. After verification, the search results that we demonstrate will be associated with that particular individual’s face only.
        <br/><br/>

        •Images are stored only temporarily and are deleted immediately after being displayed.
        <br/><br/>
        <Underlined>HOW LONG USERS PERSONAL DATA WILL BE STORED?</Underlined>
        <br/><br/>
        As a rule, personal data will be stored for the duration of the service and after its completion - for the period necessary to establish, exercise or defend of legal claims and for compliance with a legal obligation to which Alecto AI is subject (e.g., invoice issue and storage). However, in some cases, Alecto AI may store data for a shorter period.

      </Content>
    </Container>
  );
};

export default PrivacyPage;
