import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import './Mission.css';

function Mission() {
  return (
    <div className="description-container">
      <div className="description-left">
        <div className="image-container">
          {/* <img
            src="https://i.imgur.com/leCvK7i.png"
            alt="sun"
            className="featured-img"
          /> */}
          <img src="https://i.imgur.com/9jrZrhH.png" alt="Alecto AI Logo" />
        </div>
      </div>
      <div className="description-right">
        {/* <h2>How Alecto AI can help you</h2> */}
        <h1>
        Our Solution
        </h1>
        <p>
        - Designed to be compassionate and easy to use, prioritizing a human-centered approach over an institutional-focused one. 
        <br/>
        - Verifies consent directly through the individual
        <br/>
        - Searches and securely verifies countless reports, addressing the buildup of both crucial and false reports.
        <br/>
        - Addresses both systemic and systematic issues on large public platforms.
        </p>
        <Link to="/WhyPage" className="yellow-button">
          Learn more
        </Link>
      </div>
    </div>
  );
}

export default Mission;
