import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import { Button } from './Button';
import './DesignPage.css';



function DesignPage() {
  return (
    <div className='container-pd'>
      <h1>Design Logic</h1>
      <div className="containerd">
          <img
            className="images/p3.png"
            src="images/p3.png"
            alt="Profile"
            style={{  display: "inline-block;" }}
          ></img>
    
          <k>
          <p>
          Our product is based on the idea of 2A2S
          </p>
          <li>Accessible & Affordable</li>
          <li>Secure & Supportive</li><br/>
              <t>Our community is formed by compassionate human beings. </t>
              <div className='hero-btns'>
              <button type="button" class="btn btn-outline-light btn-lg btn-block"><Link to="/beta" style={{color: "white",textDecoration:"none"}}>Sign Up for Beta Test</Link></button>
              <button type="button" class="btn btn-light btn-lg btn-block"><Link to="/app-preview" style={{color: "black",textDecoration:"none"}}>App Preview</Link></button>
     

        
        
      </div>
              </k>
    </div>


   
    </div>
  )
}

export default DesignPage;