import React from 'react';
import styled from 'styled-components';
import '../../App.css';
import Footer from '../Footer';

import RHeroSection from '../RHeroSection';
import Rarticles from '../Rarticles';
import Rmentalhealthsupport from '../Rmentalhealthsupport';
import Rlegalsupport from '../Rlegalsupport';
import NewsletterSignup from '../NewsletterSignup';


const BlackBackgroundContainer = styled.div`
  background-color: black;
  color: white;
  min-height: 100vh;
`;

export default function Resources() {
  return (
    <BlackBackgroundContainer>
      <RHeroSection />
      <Rarticles />
      <Rmentalhealthsupport />
      <Rlegalsupport />
      <NewsletterSignup />
      <Footer />
    </BlackBackgroundContainer>
  );
}
