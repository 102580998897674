import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  color: white;
  padding: 1rem; /* Adjusted padding for mobile devices */
  font-family: 'Arial', sans-serif;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-direction: row; // Change to row for larger screens
    align-items: flex-start; // Adjust alignment for larger screens
    padding: 6rem; // Adjust padding for larger screens
  }

  @media (min-width: 1200px) {
    padding: 7rem; // Adjust padding for even larger screens
  }
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%; /* Adjusted width for mobile devices */
  margin-bottom: 2rem;
  margin-left: 1rem; /* Adjusted margin for mobile devices */

  @media (min-width: 768px) {
    width: 45%;
    margin-bottom: 0;
    margin-left: 7rem; /* Larger margin for larger screens */
  }
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  color: white;
  margin-bottom: 1rem;
  width: 100%;
  text-align: left;     // Aligns the heading text to the left

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Description = styled.div`
  font-size: 1.2rem;       // Adjusted for better readability on mobile
  line-height: 1.4;        // Adjusted for better spacing on mobile
  margin-bottom: 1rem;
  margin-right: 1rem;
  color: #ffff;

  @media (min-width: 576px) {
    font-size: 1.3rem;
    line-height: 1.5;
  }

  @media (min-width: 768px) {
    font-size: 1.4rem;
    line-height: 1.6;
  }
  
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  gap: 10px; /* space between items */
  justify-items: center; /* centers items horizontally */
`;

const YellowButton = styled(Link)`
  display: inline-block;
  padding: 10px 20px;
  background-color: #FBB243;
  color: #333;
  text-decoration: none;
  border-radius: 25px;
  margin-top: 10px;
`;

const ImageContainer = styled.div`
  display: flex;

  @media (min-width: 768px) {
    justify-content: flex-end;  // Align to the right in larger screens
  }
`;

const LogoImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 450px;
  margin-right: 100px;

  @media (min-width: 768px) {
    max-width: 400px;
    order: 2;
  }

  @media (max-width: 767px) {
    order: 2;
  }

  @media (min-width: 576px) {
    max-width: 325px;
  }
`;

const AlectoAIDescription = () => {
  return (
    <Container>
      <ContentSection>
        <Heading>Our Story</Heading>
        <Description>
          <Link to="/n14">
            <img src={'/images/NYTimes.png'} alt="NYTimes" style={{ borderRadius: 5, marginRight: '30px', background: 'white', width: '200px', height: 'auto' }} /> 
          </Link>
          <Link to="/n9">
            <img src={'/images/CBS.png'} alt="CBS" style={{ borderRadius: 5, background: 'white', width: '200px', height: 'auto' }} /> 
          </Link>
          <Link to="/n11">
            <img src={'/images/The-Guardian-logo.png'} alt="The Guardian" style={{ borderRadius: 5, marginRight: '30px', background: 'white', width: '200px', height: 'auto' }} /> 
          </Link>
          <Link to="/n1">
            <img src={'/images/southchinamorningpost.png'} alt="SCMP" style={{ borderRadius: 5, background: 'white', width: '200px', height: 'auto' }} /> 
          </Link>        
        </Description>
        <YellowButton to="/stories">
          Learn more about our story
        </YellowButton>
      </ContentSection>
      <ImageContainer>
        <LogoImage src="https://alectoai.com/images/logo_white_big%202.png" alt="Alecto AI Logo" />
      </ImageContainer>
    </Container>
  );
}

export default AlectoAIDescription;
