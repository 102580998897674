import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import './RHeroSection.css';

function RHeroSection() {
  const backgroundStyle = {
    backgroundImage: 'url("https://i.imgur.com/0Y72iYV.jpg")',
    backgroundSize: 'cover', // 
    backgroundRepeat: 'no-repeat', // 
    backgroundPosition: 'center center', // T
    
    backgroundColor: 'rgba(0,0,0,0.5)', // Semi-transparent black background color
    height: '45vh', // Reduced height to 75% of the viewport height
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    objectFit: 'contain',
    marginTop: '0px', // Adds margin to the top
    marginBottom: '-10px', // Adds margin to the bottom
  };

  return (
    <div className='hero-container-p' style={backgroundStyle}>
      <h1>Resources</h1>
      <div className='hero-btns-p'>
        <Link to="/legal-support" className="btn btn-light btn-lg btn-block mr-1" style={{ color: "black", textDecoration: "none" }}>Legal Support</Link>
        <Link to="/mh-support" className="btn btn-light btn-lg btn-block mr-1" style={{ color: "black", textDecoration: "none" }}>Mental Health Support</Link>
        <Link to="/articles" className="btn btn-light btn-lg btn-block mr-1" style={{ color: "black", textDecoration: "none" }}>Articles</Link>
      </div>
    </div>
  );
}

export default RHeroSection;
