import React from 'react';
import styled from 'styled-components';
import '../../App.css';
import AboutPage from '../AboutPage';
import Footer from '../Footer';
import WhyPage from '../WhyPage';
import ContactPage from '../ContactPage';


const PageContainer = styled.div`
  background-color: black;
`;

export default function AboutUs() {
  return (
  
    <PageContainer>
      <AboutPage />
      <WhyPage />
      <Footer />
    </PageContainer>
  );
}
