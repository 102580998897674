import React from 'react';
import './StoryCards.css';
import CardItemS from './CardItemS';
function StoryCards() {
  return (
    <div className='scards'>
      <h1>Media Coverage and Our Stories</h1>
      <div className='scards__container'>
        <div className='scards__wrapper'>
          <ul className='scards__items'>
            <CardItemS
              src='images/i6.jpg'
              text='In the News'
              label='about us'
              path='/news'
            />
            <CardItemS
              src='images/i7.jpg'
              text='Our Stories'
              label='about us'
              path='/stories'
            />
          </ul>

        </div>
      </div>

    </div>
  );
}

export default StoryCards;