import React from 'react';
import '../../App.css';
import BetaPage from '../BetaPage';
import Footer from '../Footer';

export default function Beta() {
  
  return (
    <>
      <BetaPage />
      <Footer />
    </>
  );
}