import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faDove, faF, faClapperboardPlay, faPlay, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';


// Styled components
const Background = styled.div`
  background-image: url("https://i.imgur.com/0Y72iYV.jpg");
  color: white;
  border: 5px solid black;
  padding: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
`;

const ContactHeader = styled.h1`
  margin-bottom: 1em; // Space below the header
`;

const EmailLine = styled.div`
  display: flex;
  flex-direction: column; // This will stack children vertically
  justify-content: center;
  align-items: center;
  margin-bottom: 2em; // Space below the email line
`;

const SocialLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap; // Allows the icons to wrap onto the next line on smaller screens
`;

const IconWrapper = styled.div`
  margin: 0.5em; // Spacing between icons
`;

const SocialLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  margin: 0 0.5em; // Spacing between text and icon
  text-decoration: none;
  color: white; // Adjust as needed
  border-radius: 5px; // Rounded corners
`;

const CHero = () => {
  return (
    <Background>
      <ContactHeader>Contact Us</ContactHeader>
      <EmailLine>
        <div>
          <FontAwesomeIcon icon={faEnvelope} size="lg" />
          <span style={{ marginLeft: '0.5em' }}>Email</span>
        </div>
        <div>
          <span style={{ marginLeft: '0.5em' }}>Media Inquiry: pr@alectoai.com </span>
        </div>
        <div>

          <span style={{ marginLeft: '0.5em' }}>Partnership: info@alectoai.com</span>
        </div>
      </EmailLine>
      
      <SocialLinksContainer>
        <IconWrapper>
          <SocialLink to="/s6">
            <FontAwesomeIcon icon={faCamera} size="lg" />
            <span style={{ marginLeft: '0.5em' }}>Instagram</span>
          </SocialLink>
        </IconWrapper>
        
        <IconWrapper>
          <SocialLink to="/s2">
            <FontAwesomeIcon icon={faDove} size="lg" />
            <span style={{ marginLeft: '0.5em' }}>Twitter</span>
          </SocialLink>
        </IconWrapper>

        <IconWrapper>
          <SocialLink to="/s4">
            <FontAwesomeIcon icon={faPlay} size="lg" />
            <span style={{ marginLeft: '0.5em' }}>YouTube</span>
          </SocialLink>
        </IconWrapper>

        <IconWrapper>
          <SocialLink to="/s5">
            <FontAwesomeIcon icon={faF} size="lg" />
            <span style={{ marginLeft: '0.5em' }}>Facebook</span>
          </SocialLink>
        </IconWrapper>
      </SocialLinksContainer>
    </Background>
  );
}

export default CHero;
