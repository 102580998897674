import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';

const newsletterContainerStyle = {
  backgroundColor: '#1a1a1a', /* Dark grey background color */
  padding: '50px',
  borderRadius: '8px',
  boxSizing: 'border-box', /* Include padding in the width calculation */
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#f7f7f7', /* Light white text color */
  marginBottom:'5px',
};

const contentRightStyle = {
  flex: '1',
  textAlign: 'center', /* Align content to the right */
  padding: '20px', /* Increase padding for better spacing */
};

const h3Style = {
  fontSize: '26px', /* Increase font size for heading */
  marginLeft: '50', /* Remove margin for better spacing */
};

const pStyle = {
  fontSize: '19px', /* Increase font size for paragraphs */
  margin: '10px 0', /* Add margin for better spacing */
  color: '#ffff',/* Light white text color */
};
const subpStyle = {
  fontSize: '19px', /* Increase font size for paragraphs */
  margin: '10px 0', /* Add margin for better spacing */
  color: 'grey',/* Light white text color */
};

const inputContainerStyle = {
  display: 'flex',
  alignItems: 'center',
};

const emailInputStyle = {
  padding: '10px',
  border: '1px solid #ccc',
  borderRadius: '4px',
  maxWidth: '300px', /* Adjust the width to make the email textbox shorter */
  flex: '1',
};

const subscribeButtonStyle = {
  backgroundColor: '#FBB243',
    color: 'black', // 
  border: 'none',
  borderRadius: '30px',
  padding: '10px 20px',
  marginLeft: '10px', // Add margin to separate the button
  cursor: 'pointer',
  textDecoration: 'none', // Remove text underline
};


const NewsletterSignup = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const updateMedia = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const contentLeftStyle = {
    flex: '1',
    textAlign: 'left',
    paddingLeft: isMobileView ? '0px' : '200px', /* Increase padding for better spacing */
  };

  return (
    <div style={newsletterContainerStyle} className="newsletter-container">
      <section style={contentLeftStyle} className="content-left">
        <h3 style={h3Style}>
          Join the Alecto AI newsletter to receive our <br />latest updates.
        </h3>
        <p style={subpStyle}>
          Alecto empowers you with sovereignty over your own <br />data and privacy.
        </p>
      </section>
      <section style={contentRightStyle} className="content-right">
        <form>
          <div style={inputContainerStyle} className="input-container">
            {/* <input
              style={emailInputStyle}
              name="email"
              type="email"
              placeholder="Your Email"
            /> */}
            <a href="https://forms.gle/KncFypyQkvTz793KA" style={subscribeButtonStyle} className="subscribe-button">
              Enter your email here
            </a>

          </div>
        </form>
      </section>
    </div>
  );
};

export default NewsletterSignup;