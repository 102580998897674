import React from 'react';
import '../../App.css';
import DesignPage from '../DesignPage';
import Footer from '../Footer';

export default function AppPreview() {
  return (
    <>
     <DesignPage/>
     <Footer/>
    </>
  );
}