import React from 'react';
import './LegalCards.css';
import CardItemL from './CardItemL';
const h1Style = {
  fontSize: '2.5rem', // Responsive font size
  color: 'white',
  marginBottom: '10px',
};

function ArticlesPage() {
  return (
    <div className='lcards'>
     <h1 style={h1Style}>Articles</h1>
     <p style={{ textAlign: 'center'  }}>We are here to support you</p>
      <div className='lcards__container'>
        <div className='lcards__wrapper'>
          
          <ul className='lcards__items'>
            <CardItemL
              src='images/i3.jpg'
              text='Why is image-based abuse?'
              label='articles'
              path='/a1'
            />
            <CardItemL
              src='images/i8.jpg'
              text='Image-based Sexual Abuse: An Introduction'
              label='articles'
              path='/a4'
            />
          </ul>
          <ul className='lcards__items'>
          <CardItemL
             src='images/i4.jpg'
             text='How to report an abusive photo on Facebook?'
             label='articles'
             path='/a2'
            />
            <CardItemL
              src='images/i5.jpg'
              text='How to deal with image-based abuse?'
              label='articles'
              path='/a3'
            />


          </ul>
        </div>
      </div>
     
    </div>
  );
}

export default ArticlesPage;