import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import '../App.css';
import './ContactPage.css';

function ContactPage() {
  return (
    <div className='hero-container-c'>
      <video src='/videos/orange-sparks.mp4' playbackRate="0.001" autoPlay loop muted />     
      <h1>Contact Us</h1>
      <div className='hero-btns-c'>
      <button type="button" class="btn btn-light btn-lg btn-block mr-1"><Link to="/s1" style={{color: "black",textDecoration:"none"}}>Tik Tok </Link></button><br/><br/>
      <button type="button" class="btn btn-light btn-lg btn-block mr-1"><Link to="/s2" style={{color: "black",textDecoration:"none"}}>Twitter </Link></button><br/><br/>
      <button type="button" class="btn btn-light btn-lg btn-block mr-1"><Link to="/s3" style={{color: "black",textDecoration:"none"}}>Medium </Link></button><br/><br/>
      <button type="button" class="btn btn-light btn-lg btn-block mr-1"><Link to="/s4" style={{color: "black",textDecoration:"none"}}>YouTube  </Link></button><br/><br/>
      <button type="button" class="btn btn-light btn-lg btn-block mr-1"><Link to="/s5" style={{color: "black",textDecoration:"none"}}>Facebook </Link></button><br/><br/>
      <button type="button" class="btn btn-light btn-lg btn-block mr-1"><Link to="/s6" style={{color: "black",textDecoration:"none"}}>Instagram</Link></button><br/><br/>
        
        
      </div>
      <br/>
      <br/>
      <p>Media Inquery Email us at pr@alectoai.com</p>
     
    </div>
  );
}

export default ContactPage;