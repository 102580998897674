import React from 'react';
import { Link } from 'react-router-dom';


const aboutUsNewsContainerStyle = {
  color: 'white',
  textAlign: 'center',
  padding: '20px', // Reduced padding for a more compact design
  width: '70%', // Full-width for responsiveness
  backgroundColor: '#2c065b',
  margin: '10px auto', // Center the container horizontally
  marginBottom: '100px', // Reduced margin for better spacing

};

const newsContentBoxStyle = {
  backgroundColor: 'purple',
  border: '1px dotted blue',
  padding: '20px',
  borderRadius: '8px',
};

const h2Style = {
  fontSize: '24px',
  marginBottom: '20px',
};

const pStyle = {
  fontSize: '18px',
  marginBottom: '20px',
};

const readMoreButtonStyle = {
  backgroundColor: '#FBB243',
  
  color: 'black', // Text color
  border: 'none',
  borderRadius: '30px', // Pill-like structure
  padding: '10px 20px',
  cursor: 'pointer',
  width: '30%',
  display: 'inline-block', // Use single quotes for consistency
  textDecoration: 'none', // Changed to camelCase
  fontWeight: 'bold',
  transition: 'background-color 0.3s ease',
};

const yellowButtonHoverStyle = {
  backgroundColor: 'goldenrod', // Change color on hover
};

const containerInnerStyle = {
  margin: '0 auto',
  maxWidth: '600px',
};

const AboutUsNews = () => {
  return (
    <div style={aboutUsNewsContainerStyle} className="about-us-news-container">
      <div style={containerInnerStyle}>
        <h2 style={h2Style}>News about us</h2>
        <p style={pStyle}>
          Learn more about us through Forbes, Time Now News, etc...
        </p>
        <Link to="/news">
          
          <button style={readMoreButtonStyle} className="read-more-button">
            Read more
          </button>
        </Link>
      </div>
    </div>
  );
};

export default AboutUsNews;
