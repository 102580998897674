import React from 'react';
import styled from 'styled-components';
import '../App.css';
import './TheStory.css';

const Container = styled.div`
  padding: 2rem;
  background-color: black;
  color: white;
  min-height: 100vh;
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Title = styled.h1`
  color: white;
  text-align: center;
  margin-bottom: 2rem;
  border: 1px solid black;
`;

const Content = styled.p`
  color: white;
  border: 1px solid none;
  padding: 50px;
  background-color:#1a1a1a;
  
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Underlined = styled.u`
  color: white;
`;

const TermsPage = () => {
  return (
    <Container>
      <Title>Alecto AI Terms of Service</Title>
      <Content>
      Welcome to Alecto AI, a leading provider of facial recognition technology services. These Terms of Service ("Terms") govern your use of our services, including any software, websites, or applications provided by Alecto AI. By accessing or using our services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our services.
        <br/><br/>
        <Underlined>1. Acceptance of Terms</Underlined>
        <br/>
        By using our services, you agree to these Terms and any additional terms and conditions that may apply to specific services. We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting. Your continued use of our services after changes are posted constitutes your acceptance of the revised Terms.
        <br/><br/>
        <Underlined>2. Description of Services</Underlined>
        <br/><br/>
        Alecto AI provides facial recognition technology services for various purposes, including but not limited to identity verification, access control, security monitoring, and analytics. Our services may include software, APIs, and other tools to facilitate facial recognition.
        <br/><br/>

        <Underlined>3. Privacy and Data Protection</Underlined>
        <br/><br/>
        We are committed to protecting your privacy and the security of your data. By using our services, you agree to our Privacy Policy, which explains how we collect, use, and disclose your information. You agree to provide accurate and complete information and to update it as necessary to maintain its accuracy.
        <br/><br/>
        <Underlined>4. User Conduct</Underlined>
        <br/><br/>
        You agree not to use our services for any unlawful or prohibited purpose. You may not interfere with or disrupt the integrity or performance of our services. You may not attempt to gain unauthorized access to any aspect of our services or to any other systems or networks connected to our services.
        <br/><br/>
        <Underlined>5. Intellectual Property</Underlined>
        <br/><br/>
        All intellectual property rights in our services, including but not limited to copyrights, trademarks, and patents, belong to Alecto AI or its licensors. You may not use our services for any purpose not expressly permitted by these Terms or by applicable law.
        <br/><br/>
        <Underlined>6. Limitation of Liability</Underlined>
        <br/><br/>
        To the fullest extent permitted by law, Alecto AI shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from (a) your access to or use of or inability to access or use our services; (b) any conduct or content of any third party on our services.
        <br/><br/>
        <Underlined>7. Indemnification</Underlined>
        <br/><br/>
        You agree to indemnify and hold harmless Alecto AI and its affiliates, officers, directors, employees, agents, and licensors from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees arising out of or relating to your use of our services or your breach of these Terms.
        <br/><br/>
        <Underlined>8. Governing Law</Underlined>
        <br/><br/>
        These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles.
        <br/><br/>
        <Underlined>9. Dispute Resolution</Underlined>
        <br/><br/>
        Any dispute arising out of or relating to these Terms or your use of our services shall be resolved exclusively by arbitration conducted in [Jurisdiction] in accordance with the rules of the [Arbitration Organization]. The arbitrator's decision shall be final and binding.
        <br/><br/>
        <Underlined>10. Severability</Underlined>
        <br/><br/>
        If any provision of these Terms is found to be invalid or unenforceable, that provision shall be limited or eliminated to the minimum extent necessary so that these Terms shall otherwise remain in full force and effect.
        <br/><br/>
        <Underlined>11. Entire Agreement</Underlined>
        <br/><br/>
        These Terms constitute the entire agreement between you and Alecto AI regarding your use of our services and supersede all prior or contemporaneous understandings and agreements, whether oral or written.
        <br/><br/>
        <Underlined>12. Contact Us</Underlined>
        <br/><br/>
        If you have any questions about these Terms or our services, please contact us at info@alectoai.com.
        <br/><br/>
        <br/><br/>
        By using our services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.

      </Content>
    </Container>
  );
};

export default TermsPage;
