import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import AppPage from '../AppPage';

export default function AppPreview() {
  return (
    <>
      <AppPage/>
    </>
  );
}