export const MenuItemsResources = [
  {
    title: 'Articles',
    path: '/articles',
    cName: 'dropdown-link'
  },  
  {
      title: 'Legal Support',
      path: '/legal-support',
      cName: 'dropdown-link'
    },
    {
      title: 'Mental Health Support',
      path: '/mh-support',
      cName: 'dropdown-link'
    },
    
  ];