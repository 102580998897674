export const MenuItemsAbout = [
    {
      title: 'Why Alecto AI',
      path: '/why-alecto',
      cName: 'dropdown-link'
    },
    {
      title: 'Terms of Service',
      path: '/terms',
      cName: 'dropdown-link'
    },
    {
      title: 'Privacy Policy',
      path: '/privacy',
      cName: 'dropdown-link'
    },
    
  ];