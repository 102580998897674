import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

function ProductHeroSection() {
  return (
    <div className='hero-container'>
      <video src='/videos/orange-sparks.mp4' playbackRate="0.001" autoPlay loop muted /> 
      <img style={{position: 'absolute', top: "100px", width: "400px"}} src="/images/background-modified.png" alt="p"/>
      <img src="/images/logo_white_big.png" alt="p"/>
      <h2> </h2>
      <h1>Alecto AI</h1>
      <p>Explore Our Product</p>
      <div className='hero-btns'>
      <button type="button" class="btn btn-outline-light btn-lg btn-block"><Link to="/app-preview" style={{color: "white",textDecoration:"none"}}> App Preview   <i className='fa fa-mobile-screen-button' /></Link></button>
      <button type="button" class="btn btn-outline-light btn-lg btn-block"><Link to="/design-logic" style={{color: "white",textDecoration:"none"}}> Design Logic <i className='fa fa-computer' /></Link></button>
        
        
      </div>
    </div>
  );
}

export default ProductHeroSection;