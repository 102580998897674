import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="footer-container">
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              <img
                src="https://i.imgur.com/axkgrmV.png"
                alt="Company Logo"
                className="social-logo-img"
              />
              Alecto AI
            </Link>
          </div>
          <div className="social-icons">
            <a
              className="social-icon-link facebook"
              href="/s5"
              target="_blank"
              aria-label="Facebook"
            >
              <i className="fab fa-facebook-f" />
            </a>
            <a
              className="social-icon-link instagram"
              href="/s6"
              target="_blank"
              aria-label="Instagram"
            >
              <i className="fab fa-instagram" />
            </a>
            <a
              className="social-icon-link youtube"
              href="/s4"
              target="_blank"
              aria-label="Youtube"
            >
              <i className="fab fa-youtube" />
            </a>
            <a
              className="social-icon-link twitter"
              href="/s2"
              target="_blank"
              aria-label="Twitter"
            >
              <i className="fab fa-twitter" />
            </a>
            <a
              className="social-icon-link linkedin"
              href="/s7"
              target="_blank"
              aria-label="LinkedIn"
            >
              <i className="fab fa-linkedin" />
            </a>
          </div>
          <small className="website-rights">
            Alecto AI © {new Date().getFullYear()}
          </small>
        </div>
      </section>
      <div className="footer-links">
        <div className="footer-column">
          <h3>Contact Us</h3>
          <Link to="/news" style={{ color: '#777', textDecoration: 'none' }}>In the News</Link>
          <br></br>
          <Link to="/stories"  style={{ color: '#777', textDecoration: 'none' }}>Our Stories</Link>
          <br></br>
          <Link to="/terms"  style={{ color: '#777', textDecoration: 'none' }}>Terms of Service</Link>
          <br></br>
          <Link to="/resources"  style={{ color: '#777', textDecoration: 'none' }} >Resources & Supports</Link>
        </div>
        <div className="footer-column">
          <h3>Why Us</h3>
          <Link to="/beta" style={{ color: '#777', textDecoration: 'none' }}>Beta Tests</Link>
          <br></br>
          <Link to="/design-logic"  style={{ color: '#777', textDecoration: 'none' }}>Design Logic</Link>
        </div>
        <div className="footer-column right-columns">
          <h3>Social Media</h3>
          <Link to="/s2" style={{ color: '#777', textDecoration: 'none' }}>Twitter</Link>
          <br></br>
          <Link to="/s4" style={{ color: '#777', textDecoration: 'none' }}>Youtube</Link>
          <br></br>
          <Link to="/s5" style={{ color: '#777', textDecoration: 'none' }}>Facebook</Link>
          <br></br>
          <Link to="/s6" style={{ color: '#777', textDecoration: 'none' }}>Instagram</Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
