import React from 'react';
import './LegalCards.css';
import CardItemL from './CardItemL';

function MHCards() {
  return (
    <div className='lcards'>
      <h1> Mental Health Support Resources </h1>
      <div className='lcards__container'>
        <div className='lcards__wrapper'>
          
          <ul className='lcards__items'>
            <CardItemL
              src='images/m1.png'
              text='Mental Health Support Solution '
              label='resources'
              path='/m1'
            />
            <CardItemL
              src='images/m2.png'
              text='Mental Health App'
              label='resources'
              path='/m2'
            />
            
          </ul>
          <ul className='lcards__items'>
          <CardItemL
              src='images/m3.jpeg'
              text='United for Global Mental Health'
              label='resources'
              path='/m3'
            />
            <CardItemL
              src='images/m4.jpeg'
              text='Global Mental Health Resources Link'
              label='resources'
              path='/m4'
            />
            <CardItemL
              src='images/m5-1.png'
              text='Global Youth Mental Health Awareness'
              label='resources'
              path='/m5'
            />
            
          </ul>
        </div>
      </div>
     
    </div>
  );
}

export default MHCards;