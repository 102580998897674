import React from 'react';

const Aboutstory = () => {
  // Inline styles
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '60px',
    marginTop: '50px', // or any other value to center it vertically as you like
    backgroundColor: '#1f1f22', // dark background
    
    
    border: '1px solid #2b2f32', // light grey border
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '900px', // max-width with a value to keep the content from stretching too far on larger screens
    marginLeft: 'auto', // centers the div horizontally
    marginRight: 'auto',
    marginBottom: '50px', // gives some space at the bottom of the section
  };

  const headingStyle = {
    textAlign: 'center',
    color: 'white',
  };

  const paragraphStyle = {
    color: 'white',
    lineHeight: '1.6', // adds space between lines for readability
    marginBottom: '15px', // adds space after each paragraph
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>The Story</h1>
      <p style={paragraphStyle}>
      Alecto’s founder is a revenge porn survivor, someone she once dated filmed her without her consent and posted the video on Pornhub without her knowledge. 
      </p>
      <p style={paragraphStyle}>
      In her darkest hour, she was forced to relive her trauma over and over again while going through various different porn sites and asking them to remove her video. Despite her relentless effort, the road to seek justice was never easy. Her experience inspired her to found this company and embark on the journey to help others. 
      </p>
      {/* <p style={paragraphStyle}>
      Her experience inspired her to found this company and embark on the journey to help others. 
      </p> */}
    </div>
  );
}

export default Aboutstory;
