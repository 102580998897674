import React from 'react';
import '../../App.css';
import TheStory from '../TheStory';
import Footer from '../Footer';

export default function Stories() {
  return (
    <>
      <TheStory />
      <Footer />
    </>
  );
}