import React from 'react';
import styled from 'styled-components';
import '../../App.css';
import TermsPage from '../TermsPage';
import Footer from '../Footer';
import NewsletterSignup from '../NewsletterSignup';

const BlackBackgroundContainer = styled.div`
  background-color: black;
  color: white;
  min-height: 100vh;
`;


export default function Terms() {
  return (
    <>
      <BlackBackgroundContainer>
      <TermsPage />
      <NewsletterSignup/>
      <Footer />
      </BlackBackgroundContainer>
    </>
  );
}