import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from './Button';

const Heading = styled.header`
  text-align: center;
  margin-bottom: 2rem;
  color: white;
`;

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 1000px;
  margin: 0 auto;
  background-color: black;
  padding: 10px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin: 0.5rem 0;
  font-weight: bold;
  color: white;
`;

const Input = styled.input`
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: none;
  border-bottom: 2px solid white;
  background-color: transparent;
  color: white;

  &:focus {
    outline: none;
  }
`;

const TextArea = styled.textarea`
  grid-column: 1 / -1;
  padding: 0.5rem;
  border: none;
  border-bottom: 2px solid white;
  background-color: transparent;
  color: white;
  margin-bottom: 1rem;
`;

const ButtonContainer = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between; // Spaced equally

  @media (max-width: 768px) {
    flex-direction: column; // Stack buttons vertically on smaller screens
    align-items: center;
  }
`;

const StyledLink = styled(Link)`
  padding: 0.5rem 2rem;
  background-color: #fbb243;
  color: black;
  border: none;
  border-radius: 40px;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  margin-bottom: 1rem; // Add bottom margin for mobile view

  &:hover {
    background-color: #fba123; // A slightly different color on hover
  }

  @media (max-width: 768px) {
    width: 100%; // Full width on smaller screens
    margin-bottom: 0.5rem; // Less space on mobile
  }
`;

const SubmitButton = styled.button`
  padding: 0.5rem 2rem;
  background-color: #fbb243;
  color: black;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #fba123;
  }

  @media (max-width: 768px) {
    width: 100%; // Full width on smaller screens
  }
`;

const Cform = () => {
  return (
    <Heading>
      <h1>Leave us a message</h1>
      <StyledForm action="/action_page.php" method="post">
        <InputGroup>
          <Label htmlFor="fname">First Name</Label>
          <Input
            type="text"
            placeholder="Enter First Name"
            name="firstname"
            required
          />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="lname">Last Name</Label>
          <Input
            type="text"
            placeholder="Enter Last Name"
            name="lastname"
            required
          />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="email">Email Address</Label>
          <Input type="text" placeholder="Enter Email" name="email" required />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="phone">Phone Number</Label>
          <Input
            type="tel"
            pattern="[0-9]{3}-[0-9]{4}"
            placeholder="(xxx) xxx-xxxx"
            name="phonenumber"
          />
        </InputGroup>
        <TextArea
          rows="5"
          placeholder="Type your message here..."
          name="message"
        ></TextArea>
        <ButtonContainer>
          <SubmitButton type="submit">Submit</SubmitButton>
        </ButtonContainer>
      </StyledForm>
    </Heading>
  );
};

export default Cform;
