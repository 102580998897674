import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Beta from './components/pages/Beta';
import Resources from './components/pages/Resources';
import Products from './components/pages/Products';
import AboutUs from './components/pages/AboutUs';
import OurStory from './components/pages/OurStory';
import AppPreview from './components/pages/AppPreview';
import DesignLogic from './components/pages/DesignLogic';
import LegalSupport from './components/pages/LegalSupport';
import MentalHealthSupport from './components/pages/MentalHealthSupport';
import News from './components/pages/News';
import Stories from './components/pages/Stories';
import WhyAlecto from './components/pages/WhyAlecto';
import ContactUs from './components/pages/ContactUs';
import Articles from './components/pages/Articles';
import Terms from './components/pages/Terms';
import ArtImag from './components/pages/ArtImag';
import ArtImagAb from './components/pages/ArtImagAb';
import ArtImagPr from './components/pages/ArtImagPr';
import PrivacyPage from './components/PrivacyPage';




import { AuthProvider } from "./contexts/AuthContext"
import Login from "./components/Login"
import Signup from "./components/Signup"
import PrivateRoute from "./components/PrivateRoute"
import ForgotPassword from "./components/ForgotPassword"
import UpdateProfile from "./components/UpdateProfile"

import ReactGA from 'react-ga';
const TRACKING_ID = "G-E7WF2XYQL1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <><Router>
    <Navbar />
    <AuthProvider>
      <Switch>
      <Route path='/' exact component={Home} />
      <Route path='/resources' component={Resources} />
      <Route path='/products' component={Products} />
      <Route path='/beta' component={Beta} />
      <Route path='/terms' component={Terms} />
      <Route path='/privacy' component={PrivacyPage} />
      <Route path='/about-us' component={AboutUs} />
      <Route path='/our-story' component={OurStory} />
      <Route path='/app-preview' component={AppPreview} />
      <Route path='/design-logic' component={DesignLogic} />
      <Route path='/legal-support' component={LegalSupport} />
      <Route path='/mh-support' component={MentalHealthSupport} />
      <Route path='/stories' component={Stories} />
      <Route path='/news' component={News} />
      <Route path='/why-alecto' component={WhyAlecto} />
      <Route path='/contact-us' component={ContactUs} />
      <Route path='/articles' component={Articles} />
      <Route path='/at1' component={ArtImag} />
      <Route path='/at2' component={ArtImagAb} />
      <Route path='/at3' component={ArtImagPr} />

      <Route path='/l1' component={() => { 
                window.location.href = 'https://cybercivilrights.org/'; 
                return null;}}/>
      <Route path='/l2' component={() => { 
                window.location.href = 'https://www.connectsafely.org/'; 
                return null;}}/>
      <Route path='/l3' component={() => { 
                window.location.href = 'https://withoutmyconsent.org/'; 
                return null;}}/>
      <Route path='/l4' component={() => { 
                window.location.href = 'https://www.loveisrespect.org/'; 
                return null;}}/>
      <Route path='/l5' component={() => { 
                window.location.href = 'https://1800victims.org/information-for-victims/crime-types/revenge-porn/'; 
                return null;}}/>
      <Route path='/l6' component={() => { 
                window.location.href = 'https://www.thehotline.org/resources/revenge-porn/'; 
                return null;}}/>
      
      <Route path='/s1' component={() => { 
                window.location.href = 'https://www.tiktok.com/@alectoai?lang=en'; 
                return null;}}/>
      <Route path='/s2' component={() => { 
                window.location.href = 'https://twitter.com/AiAlecto'; 
                return null;}}/>
      <Route path='/s3' component={() => { 
                window.location.href = 'https://alectoai.medium.com/'; 
                return null;}}/>
      <Route path='/s4' component={() => { 
                window.location.href = 'https://www.youtube.com/channel/UCfMjg5J7g9w1H3C6BNIt7fg'; 
                return null;}}/>
      <Route path='/s5' component={() => { 
                window.location.href = 'https://www.facebook.com/Alecto-AI-100642448755381'; 
                return null;}}/>
      <Route path='/s6' component={() => { 
                window.location.href = 'https://www.instagram.com/alectoai/'; 
                return null;}}/>
      <Route path='/s7' component={() => { 
                window.location.href = 'https://www.linkedin.com/in/alecto-ai-5b3b4a21b/'; 
                return null;}}/>

      <Route path='/m1' component={() => { 
                window.location.href = 'https://www.mentalhealth-support.com/'; 
                return null;}}/>
      <Route path='/m2' component={() => { 
                window.location.href = 'https://www.getapp.com/healthcare-pharmaceuticals-software/mental-health/?camp=bing&utm_content=o&utm_source=ps-bing&utm_campaign=COM_MISC_Desktop_BR-Mental_Health&utm_medium=cpc&account_campaign_id=267722366&account_adgroup_id=1187473264644878&ad_id=74217285643300&utm_term=programs%20for%20mental%20health&matchtype=b&msclkid=3238c3ebe04c1ab0a516a3046698cee0'; 
                return null;}}/>
      <Route path='/m3' component={() => { 
                window.location.href = 'http://unitedgmh.org/'; 
                return null;}}/>
      <Route path='/m4' component={() => { 
                window.location.href = 'https://checkpointorg.com/'; 
                return null;}}/>
      <Route path='/m5' component={() => { 
                window.location.href = 'https://globalyouthmentalhealthawareness.org/'; 
                return null;}}/>

      <Route path='/n1' component={() => { 
                window.location.href = 'https://www.scmp.com/week-asia/people/article/3141469/chinese-woman-found-video-herself-pornhub-her-new-app-aims-help'; 
                return null;}}/>
      <Route path='/n2' component={() => { 
                window.location.href = 'https://forbes.cz/porno-bez-souhlasu-chytra-aplikace-chce-zamezit-jeho-sireni/'; 
                return null;}}/>
      <Route path='/n3' component={() => { 
                window.location.href = 'https://www.independent.co.uk/asia/china/pornhub-china-alecto-ai-app-b1886580.html'; 
                return null;}}/>
      <Route path='/n4' component={() => { 
                window.location.href = 'https://theworldnews.net/gb-news'; 
                return null;}}/>
      <Route path='/n5' component={() => { 
                window.location.href = 'https://www.timesnownews.com/technology-science/article/chinese-woman-develops-app-for-victims-of-online-abuse-after-finding-her-video-on-pornhub/788581'; 
                return null;}}/>
      <Route path='/n6' component={() => { 
                window.location.href = 'https://www.businesstoday.in/trending/world/story/chinese-woman-develops-app-for-survivors-after-finding-her-video-on-pornhub-301896-2021-07-20'; 
                return null;}}/>
      <Route path='/n7' component={() => { 
                window.location.href = 'https://actualidad.rt.com/actualidad/398224-china-pornhub-video-crear-app-victimas'; 
                return null;}}/>
      <Route path='/n8' component={() => { 
                window.location.href = 'https://www.youtube.com/watch?v=sTg31QxWw4o&ab_channel=CBSEveningNews'; 
                return null;}}/>
      <Route path='/n9' component={() => { 
                window.location.href = 'https://www.youtube.com/watch?v=OxtlYGxfOv8&ab_channel=CBSMornings'; 
                return null;}}/>
      <Route path='/n10' component={() => { 
                window.location.href = 'https://people.com/breeze-liu-fighting-back-against-ai-deepfake-porn-8649589'; 
                return null;}}/>
      <Route path='/n11' component={() => { 
                window.location.href = 'https://www.instagram.com/reel/C6YGme1IjF7/?igsh=MWQ1ZGUxMzBkMA%3D%3D'; 
                return null;}}/>
      <Route path='/n12' component={() => { 
                window.location.href = 'https://www.thestreet.com/technology/how-one-deepfake-revenge-porn-victim-aims-to-change-the-system'; 
                return null;}}/>
      <Route path='/n13' component={() => { 
                window.location.href = 'https://www.nytimes.com/2024/04/08/opinion/deepfake-porn-tech.html?unlocked_article_code=1.jE0.EYhg.oQmfi08AfeTk&smid=url-share'; 
                return null;}}/>
      <Route path='/n14' component={() => { 
                window.location.href = 'https://www.nytimes.com/2024/03/23/opinion/deepfake-sex-videos.html?unlocked_article_code=1.jE0.3o82.NaY2abxFx-fY&smid=url-share'; 
                return null;}}/>
      
      <Route path='/a1' component={() => { 
                window.location.href = "https://www.esafety.gov.au/key-issues/image-based-abuse"; 
                return null;}}/>
      <Route path='/a2' component={() => { 
                window.location.href = 'https://www.facebook.com/help/189722821075378'; 
                return null;}}/>
      <Route path='/a3' component={() => { 
                window.location.href = 'https://www.esafety.gov.au/key-issues/image-based-abuse/support/10-ways-to-start-healing'; 
                return null;}}/>
      <Route path='/a4' component={() => { 
                window.location.href = 'https://endcyberabuse.org/law-intro/'; 
                return null;}}/>
      
      <Route path='/beta-feedback' component={() => { 
                window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSdAM9j7GKILh1u7QINUlOS1KANQPteGI52prZw75seg6-lPTA/viewform'; 
                return null;}}/>
      <Route path='/beta-test' component={() => { 
                window.location.href = 'https://testflight.apple.com/join/iuRjnLpR'; 
                return null;}}/>

      <Route path='/appleapp' component={() => { 
                window.location.href = 'https://apps.apple.com/us/app/alecto-ai/id1577004341'; 
                return null;}}/>
      <Route path='/androidapp' component={() => { 
                window.location.href = 'https://play.google.com/store/apps/details?id=com.alectoreactandroidapp'; 
                return null;}}/>


        <PrivateRoute path="/update-profile" component={UpdateProfile} />
        <Route path="/signup" component={Signup} />
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
      </Switch>
    </AuthProvider>
  </Router>
  </>
  );
}
export default App;
