import React from 'react';
import '../../App.css';
import styled from 'styled-components';
import Footer from '../Footer';
import ArticlesPage from '../ArticlesPage';
import NewsletterSignup from '../NewsletterSignup';

const BlackBackgroundContainer = styled.div`
  background-color: black;
  color: white;
  min-height: 100vh;
`;

export default function Articles() {
  return (
    <>
      <BlackBackgroundContainer>
        <ArticlesPage />
        <NewsletterSignup />
        <Footer />
      </BlackBackgroundContainer>
    </>
  );
}
