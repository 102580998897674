import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import AImagAb from '../AImagAb';

export default function ArtImagAb() {
  return (
    <>
      <AImagAb/>
      <Footer />
    </>
  );
}