import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import AImagPr from '../AImagPr';

export default function ArtImagPr() {
  return (
    <>
      <AImagPr/>
      <Footer />
    </>
  );
}