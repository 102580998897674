import React, { useState } from 'react';
import styled from 'styled-components';

const MissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
 
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;


  max-width: 1200px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  padding: 20px;
`;
const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row; /* Arrange items in a row */
  align-items: center;
  justify-content: space-between; /* Add spacing between images */
  padding: 50px;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle box shadow */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center; /* Center items vertically */
    padding: 20px; /* Reduce padding for smaller screens */
  }
`;

const StyledImage = styled.img`
  width: calc(50% - 10px); /* Adjust width to fit two images side by side */
  max-height: 400px; /* Limit the maximum image height */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;

  @media (max-width: 768px) {
    width: 100%; /* Full width on smaller screens */
    margin: 10px 0; /* Adjust margin for stacking */
  }
`;

const StyledImage1 = styled.img`
  width: calc(90% - 10px); /* Match the width of StyledImage */
  max-height: 400px; /* Limit the maximum image height */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px; /* Add margin to the top and bottom to align with StyledImage */

  @media (max-width: 768px) {
    width: 100%; /* Full width on smaller screens */
    margin: 10px 0; /* Adjust margin for stacking */
  }
`;

const StyledImage2 = styled.img`
  width: calc(90% - 10px); /* Match the width of StyledImage */
  max-height: 400px; /* Limit the maximum image height */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px; /* Add margin to the top and bottom to align with StyledImage */

  @media (max-width: 768px) {
    width: 100%; /* Full width on smaller screens */
    margin: 10px 0; /* Adjust margin for stacking */
  }
  `; 
const Heading = styled.h2`
  color: #fff;
  margin-bottom: 0.5em;
  text-align: center;
`;

const Paragraph = styled.p`
  line-height: 1.6;
  color: #ccc;
  text-align: left;
`;

const AboutMission = () => {
  const [imageHeight, setImageHeight] = useState('auto');

  const handleImageLoad = (event) => {
    const newHeight = event.target.offsetHeight / 2;
    setImageHeight(`${newHeight}px`); // Ensuring height is set in pixels
  };

  return (
    <MissionContainer>
      <ContentContainer>
        <TextContainer>
          <h1>Our Mission</h1>
          <Paragraph>
            Digital abuse, like revenge porn, is incredibly destructive, even
            fatal. Platforms rely on AI to combat it, but the industry's lack of
            diversity and human-centric focus hinders progress. Current facial
            recognition tools struggle with accuracy, particularly for women and
            people of color.
          </Paragraph>
          <Paragraph>
            Alecto, founded by a revenge porn survivor and women of color,
            fights image-based abuse and racial bias with advanced,
            compassionate technology. Our biometrically secure, anti-racist
            facial recognition tool empowers individuals to swiftly search for
            harmful images of themselves online.
          </Paragraph>
          <Paragraph>
            Alecto isn't just a product; it's a movement against digital abuse
            and biased algorithms. We envision a future free from algorithmic
            injustice and digital abuse.
          </Paragraph>
        </TextContainer>
        <ImageContainer>
          <StyledImage
            src="https://images.pexels.com/photos/7480125/pexels-photo-7480125.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="Alecto's mission against digital abuse"
            onLoad={handleImageLoad}
          />
          <div>
            <StyledImage1
              src="https://images.pexels.com/photos/5871420/pexels-photo-5871420.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="Alecto's advanced facial recognition tool"
              style={{ height: imageHeight }} // Inline style for dynamic height
            />
            <StyledImage2
              src="https://images.pexels.com/photos/5871420/pexels-photo-5871420.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="Alecto's advanced facial recognition tool"
              style={{ height: imageHeight }} // Inline style for dynamic height
            />
          </div>
        </ImageContainer>
      </ContentContainer>
    </MissionContainer>
  );
};

export default AboutMission;

// import React, { useState } from 'react';
// import styled from 'styled-components';

// const MissionContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: stretch;
//   margin: 20px;
//   background-color: black;
//   padding: 150px;
//   @media (max-width: 768px) {
//     flex-direction: column;
//   }
// `;

// const TextContainer = styled.div`
//   width: 45%;
//   margin-right: 5%;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   @media (max-width: 768px) {
//     width: 100%;
//     margin-right: 0;
//   }
// `;

// const ImageContainer = styled.div`
//   width: 50%;
//   display: flex;
//   align-items: flex-start;
//   @media (max-width: 768px) {
//     width: 100%;
//     flex-direction: column;
//     align-items: center;
//   }
// `;

// const ImagesSideBySideContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
// `;

// const StyledImage1 = styled.img`
//   width: 100%;
//   margin-bottom: 20px;
//   box-shadow: 0 4px 8px rgba(0,0,0,0.1);
//   border-radius: 8px;
//   padding: 2px;
// `;

// const StyledImage2 = styled.img`
//   width: 100%;
//   margin-bottom: 20px;
//   box-shadow: 0 4px 8px rgba(0,0,0,0.1);
//   border-radius: 8px;
//   padding: 2px;
// `;

// const StyledImage3 = styled.img`
//   width: 100%;
//   margin-bottom: 20px;
//   box-shadow: 0 4px 8px rgba(0,0,0,0.1);
//   border-radius: 8px;
//   padding: 2px;
// `;

// // Styled components for StyledImage2 and StyledImage3 remain unchanged

// const Heading = styled.h2`
//   color: #fff;
//   margin-bottom: 0.5em;
// `;

// const Paragraph = styled.p`
//   line-height: 1.6;
//   color: #ccc;
// `;

// const AboutMission = () => {
//   const [secondImageHeight, setSecondImageHeight] = useState('auto');

//   const handleImageLoad = (event) => {
//     const halfHeight = event.target.offsetHeight / 2;
//     setSecondImageHeight(`${halfHeight}px`); // Ensuring height is set in pixels
//   };

//   return (
//     <MissionContainer>
//       <TextContainer>
//         <Heading>Our Mission</Heading>
//         <Paragraph>
//           Digital abuse, like revenge porn, is incredibly destructive, even fatal. Platforms rely on AI to combat it, but the industry's lack of diversity and human-centric focus hinders progress. Current facial recognition tools struggle with accuracy, particularly for women and people of color.
//         </Paragraph>
//         <Paragraph>
//           Alecto, founded by a revenge porn survivor and women of color, fights image-based abuse and racial bias with advanced, compassionate technology. Our biometrically secure, anti-racist facial recognition tool empowers individuals to swiftly search for harmful images of themselves online.
//         </Paragraph>
//         <Paragraph>
//           Alecto isn't just a product; it's a movement against digital abuse and biased algorithms. We envision a future free from algorithmic injustice and digital abuse.
//         </Paragraph>
//       </TextContainer>
//       <ImageContainer>
//         <StyledImage1
//           src="https://images.pexels.com/photos/7480125/pexels-photo-7480125.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
//           alt="Alecto's mission against digital abuse"
//           onLoad={handleImageLoad}
//         />
//         <ImagesSideBySideContainer>
//           <StyledImage2
//             src="https://images.pexels.com/photos/5871420/pexels-photo-5871420.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
//             alt="Alecto's advanced facial recognition tool"
//             style={{ height: secondImageHeight }} // Apply the dynamic height
//           />

//           <StyledImage2
//             src="https://images.pexels.com/photos/5871420/pexels-photo-5871420.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
//             alt="Alecto's advanced facial recognition tool"
//             style={{ height: secondImageHeight }} // Apply the dynamic height
//           />
//           {/* StyledImage3 would go here */}
//         </ImagesSideBySideContainer>
//       </ImageContainer>
//     </MissionContainer>
//   );
// };

// export default AboutMission;
