import React from 'react';
import '../App.css';
import './AboutPage.css';
import { Link } from 'react-router-dom';

function AboutPage() {
  return (
    <div className='hero-container-a'>
      <video src='/videos/orange-sparks.mp4' playbackRate="0.001" autoPlay loop muted /> 
      <h1>Contact Us</h1>
      <div className='hero-btns-a'>
        
      <button type="button" class="btn btn-light btn-lg btn-block mr-1"><Link to="/contact-us" style={{color: "black",textDecoration:"none"}}>Contact Us </Link></button><br/>
        <br/>
      </div>
        
     
    </div>
  );
}

export default AboutPage;