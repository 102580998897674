import React from 'react';
import '../App.css';

import './TheStory.css';

function AImag() {
  return (
    <div className='hero-container-s'>
      <h1>What is online image abuse?</h1>
      <p>
      Online image abuse, also known as revenge porn or non-consensual pornography, refers to the act of sharing, distributing, or posting intimate or sexually explicit images or videos of a person without their consent. These images or videos are often shared with the intention to harm, humiliate, or exploit the individual depicted in the images.
      <br/> <br/>
      Online image abuse can have serious and lasting consequences for victims, including emotional distress, reputational damage, loss of privacy, and even legal ramifications. Victims of online image abuse may experience harassment, bullying, and discrimination as a result of the non-consensual sharing of their private images.
      <br/>  <br/>
      It's important to note that online image abuse is illegal in many jurisdictions, and laws have been enacted in some places to specifically address this issue. However, enforcement and legal remedies vary by jurisdiction, and it can be complex and challenging to address online image abuse from a legal perspective.
      <br/>  <br/> 
      If you or someone you know is a victim of online image abuse, it's important to seek support from trusted individuals, law enforcement, and victim advocacy organizations. It's also crucial to take steps to protect your privacy and well-being, such as reporting the abuse, documenting evidence, and seeking legal assistance if possible. Remember, no one deserves to have their privacy violated or be subjected to online image abuse, and there are resources available to help those affected by this type of harm.

      </p>
        <br/><br/><br/><h2>Let's keep the flame burning, <br/>share your story, <br/>and stand with us for a better future! </h2>
        <br/>
      </div>
        
     

  );
}

export default AImag;