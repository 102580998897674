import React from 'react';
import '../App.css';
import './WhyPage.css';



function WhyPage() {
  return (
    <div className='hero-container-pw'>
      <h1>Why Alecto AI</h1>
      <h2>The Problems We are Trying to Solve:</h2>
      <img src="images/p1.png" alt="p"/>
      <h2><i class="fa-solid fa-arrow-down"></i> <h2> </h2> Our Solution:</h2>
      <p>
      <li>A consumer-level tool: Accessible and affordable for the public;</li>
      <li>Safe and secure, can’t be abused by unauthorized 3rd parties; </li>
      <li>Compassionate, easy to use;</li>
      <li>Human-centered instead of institutional-focused;</li>
      <li>Solves both the systemic issue and systematic issue.</li></p>
     
      <div className="containerw">
          <img
            className="images/p2.png"
            src="images/p2.png"
            alt="p"
            style={{ marginTop: "100px", display: "inline-block;" }}
          ></img>
          <k>
          Digital abuse such as revenge porn is extremely destructive for its victims, it can kill. Platforms rely on AI tools to fight digital abuse. However, the AI industry has long been dominated by white males and is mostly institutionally focused instead of human-centered. On that basis, existing facial recognition tools have a lower accuracy rate when it comes to recognizing faces of women or people of color.<br /> <br />
          Initiated by a revenge porn survivor, built by women of color, Alecto aims to end both image-based abuse and racist facial recognition with compassion and cutting-edge technology. We offer a bio-metrical protected, anti-racist facial recognition tool that allows individuals to quickly search for infringing images of themselves online.<br /><br />
          Alecto is beyond a product, it’s a movement against digital abuse and algorithmic biases. We are striving towards a future where no one will ever have to suffer from algorithmic injustice or digital abuse. 
          </k>
    </div>
    </div>
  );
}

export default WhyPage;