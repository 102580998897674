import React from 'react';
import '../App.css';
import './AppPage.css';
import { Link } from 'react-router-dom';



function AppPage() {
  return (
    <div className='container-pa'>
      <div className="containera">
          <img
            className="images/p4.gif"
            src="images/new-design.gif"
            alt="Profile"
            style={{ marginTop: "50px", display: "inline-block;" }}
          ></img>
    
          <k>
          <p>
          </p>
          <t>Alecto AI aims to end online image abuse with compassion and cutting-edge technology. We provide a secure facial recognition tool which allow you to quickly search for images of yourself online, and a resources page with mental health and legal support. 
              We will also offer a community where users can reach out and tell their stories (anonymously) or just show some love and support to others.</t> <br/><br/>
              <t>Sign up for our beta test: <br/>
             </t>
              <div className='hero-btns'>
          
      <button type="button" class="btn btn-outline-light btn-lg btn-block"><Link to="/beta" style={{color: "white",textDecoration:"none"}}> Mobile App  <i className='fa fa-mobile-screen-button' /></Link></button>
      <button type="button" class="btn btn-outline-light btn-lg btn-block"><Link to="/beta" style={{color: "white",textDecoration:"none"}}> Desktop App<i className='fa fa-computer' /></Link></button>
        
        
      </div>
      </k>
              
    </div>
    </div>
  );
}

export default AppPage;