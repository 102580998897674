import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faSmileBeam, faLightbulb, faDollarSign,faShield,faIdCardClip} from '@fortawesome/free-solid-svg-icons';

// Styled-components definitions
const PageContainer = styled.div`
  text-align: center;
  color: white;
`;

const Heading = styled.h2``;

const BoxesContainer = styled.div`
  display: flex;
  justify-content: center; // Adjusted to center for closer positioning
  align-items: center;
  flex-wrap: wrap;
  gap: 10px; // Reduced gap for closer positioning
`;

const IconWrapper = styled.div`
  width: 100px; // Adjust size as needed
  height: 100px; // Adjust size as needed
  border: 1px solid rgb(26, 26, 26); // Adjust color and size as needed
  border-radius: 50%; // Makes the border round
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom:10px;
  background-color: rgb(41, 39, 39);
`;

const SolutionBox = styled.div`
  width: 220px; // Slightly increased to accommodate the icon wrapper
  height: 290px; // Adjusted height
  display: flex;
  flex-direction: column;
  justify-content: space-between; // Changed to space-between to distribute space
  align-items: center;
  background-color: rgb(26, 26, 26);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const SolutionText1 = styled.p`
  color: #FBB243;
`;


const SolutionText = styled.p`
  color: white;
  margin-bottom:-40px;
`;

// React component
const AboutSolutions = () => {
  return (
    <PageContainer>
      <h1>Our Solutions</h1>
      <BoxesContainer>
        <SolutionBox>
          <IconWrapper>
            <FontAwesomeIcon icon={faDollarSign} size="2x" />
          </IconWrapper>
          <SolutionText>A consumer-level tool </SolutionText>
          <SolutionText1>Accessible and affordable for the public </SolutionText1>
          

        </SolutionBox>
        <SolutionBox>
          <IconWrapper>
          <FontAwesomeIcon icon={faShield} size="2x"/>
          </IconWrapper>
          <SolutionText>Safe and secure</SolutionText>
          <SolutionText1>can’t be abused by unauthorized 3rd parties</SolutionText1>
        </SolutionBox>
        <SolutionBox>
          <IconWrapper>
            <FontAwesomeIcon icon={faSmileBeam} size="2x" />
          </IconWrapper>
          <SolutionText>Easy to use</SolutionText>
          <SolutionText1>We offer best experience to support users</SolutionText1>
        </SolutionBox>
        <SolutionBox>
          <IconWrapper>
          <FontAwesomeIcon icon={faIdCardClip} size="2x" />
          </IconWrapper>
          <SolutionText>Compassionate</SolutionText>
          <SolutionText1>Human-centered instead of institutional-focused</SolutionText1>
          
        </SolutionBox>
        <SolutionBox>
          <IconWrapper>
          <FontAwesomeIcon icon={faLightbulb} size="2x" />
          </IconWrapper>
          <SolutionText>Solve Real Issue</SolutionText>
          <SolutionText1>Solves both the systemic issue and systematic issue</SolutionText1>
        </SolutionBox>
      </BoxesContainer>
    </PageContainer>
  );
};

export default AboutSolutions;

