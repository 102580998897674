import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import ArticlesPage from '../ArticlesPage';
import AImag from '../AImag';

export default function ArtImag() {
  return (
    <>
      <AImag/>
      <Footer />
    </>
  );
}