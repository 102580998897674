import React from 'react';
import CardItem from './CardItem';

const h1Style = {
  fontSize: '2.5rem', // Responsive font size
  color: 'white',
  marginBottom: '10px',
};

const pStyle = {
  fontSize: '1.5rem', // Responsive font size
  color: '#ffff',
  lineHeight: '1.5',
  maxWidth: '600px', // Limit text width on large screens
  textAlign: 'center', // Center text on all screens
};

const centerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
};

function Cards() {
  return (
    <div className="cards">
      <div style={centerStyle}>
        <h1 style={h1Style}>Articles</h1>
        
      </div>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/i5.jpg"
              text="What is online image abuse?"
              label="articles"
              path="/at1"
            />
            <CardItem
              src="images/i3.jpg"
              text="What should I do if I became a victim of online image abuse?"
              label="articles"
              path="/at2"
            />
            <CardItem
              src="images/i4.jpg"
              text="How to prevent online image abuse"
              label="articles"
              path="/at3"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;

