import React from 'react';
import '../../App.css';
import StoryCards from '../StoryCards';
import Footer from '../Footer';

export default function OurStory() {
  return (
    <>
      <StoryCards/>
      <Footer />
    </>
  );
}