import React from 'react';
import './LegalCards.css';
import CardItemL from './CardItemL';

function LegalCards() {
  return (
    <div className='lcards'>
      
      <h1> Legal Support Resources </h1>
      <p style={{ textAlign: 'center'  }}>We are here to support you</p>
      <div className='lcards__container'>
        <div className='lcards__wrapper'>
          
          <ul className='lcards__items'>
            <CardItemL
              src='images/l1-2.png'
              text='Cyber Civil Rights Initiative'
              label='resources'
              path='/l1'
            />
            <CardItemL
              src='images/l2.png'
              text='Connect Safely'
              label='resources'
              path='/l2'
            />
            <CardItemL
              src='images/l3-1.png'
              text='Without My Consent'
              label='resources'
              path='/l3'
            />
            
            
          </ul>
          <ul className='lcards__items'>
           
            <CardItemL
              src='images/l4.png'
              text='Love is Respect'
              label='resources'
              path='/l4'
            />
            <CardItemL
              src='images/l5-1.png'
              text='The Victims of Crime Resource Center'
              label='resources'
              path='/l5'
            />
            <CardItemL
              src='images/l6-1.png'
              text='The Hotline'
              label='resources'
              path='/l6'
            />
          </ul>
        </div>
      </div>
      
    </div>
  );
}

export default LegalCards;