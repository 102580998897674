import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  padding: 60px;
  font-family: 'Arial', sans-serif;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-evenly; // Ensure items are evenly spaced
  }

  @media (min-width: 1200px) {
    padding: 7rem;
  }
`;
const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
  margin-left: 10rem;

  @media (min-width: 768px) {
    width: 50%;
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

const BiggerHeading = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  
  @media only screen and (max-width: 600px) {
    .heading {
      font-size: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  @media (min-width: 768px) {
       font-size: 2.5rem;
  }

  @media (min-width: 992px) {
    font-size: 5rem;
  }
  
`;

const Description = styled.p`
  font-size: 2rem;
  line-height: 1.5;
  margin-bottom: 1rem;
    font-weight: 300;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column; // Stack the images vertically
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem; // add margin for space between image and buttons

  @media (min-width: 768px) {
    flex-direction: row; // Place images side by side on larger screens
    justify-content: space-around; // Add space around the images
    width: 50%; // Take up half of the container width
  }
`;

const PhoneImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 300px;

  @media (min-width: 768px) {
    max-width: 250px;
  }

  @media (min-width: 576px) {
    max-width: 200px;
  }
`;



const ButtonImages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 767px) {
    order: 2;
  }
  @media (min-width: 768px) {
    justify-content: flex-start;
    order: 2;  // Change the order here for mobile
  }
`;

const AppButton = styled(Link)`
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 30px;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-family: 'Arial', sans-serif;
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #333;
    transform: translateY(-2px);
  }
`;

const Icon = styled.i`
  margin-right: 0.5rem;
`;

function HeroSection() {
  return (
    <HeroContainer>
      <ContentSection>
       
        <Description>
        Let's keep the flame burning, share your story, and stand with us for a better future!
        </Description>
        
        <ButtonImages>
          <AppButton as="a" href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
            <Icon className="fa-brands fa-apple" /> Download 
          </AppButton>
          <AppButton as="a" href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
            <Icon className="fa-brands fa-android" /> Download 
          </AppButton>
        </ButtonImages>
      </ContentSection>
      <RightSection>
        <PhoneImage src="/images/iphonescreen.png" alt="Phone" />
      </RightSection>
    </HeroContainer>
  );
}

export default HeroSection;
