import React from 'react';

const About2 = () => {
  const backgroundStyle = {
    backgroundImage: 'url("https://i.imgur.com/0Y72iYV.jpg',
    color: 'white',
    padding: '100px',
    backgroundSize: 'cover', // This will make sure the image covers the div completely
    backgroundRepeat: 'no-repeat', // This will prevent the image from repeating
    backgroundPosition: 'center center', // This will center the image in the div
  };

  return (
    <div style={backgroundStyle}>
      <h1>About Us</h1>
      <p style={{ textAlign: 'center'  }}>We are here to support you</p>
    </div>
  );
}

export default About2;